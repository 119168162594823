<template>
  <div>
    <section class="hero">
      <div class="hero-body">
        <div class="buttons" key="logoutBtn">
          <router-link
            class="button is-primary"
            :style="{
              backgroundColor: '#1062FE',
              marginLeft: '15px',
            }"
            to="/dashboard/discord/redirect"
          >
            <strong>Login with Discord</strong>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Discord",
  components: {},
  data() {},
};
</script>
